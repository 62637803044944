import { motion } from "framer-motion";

const Backdrop = ({ isOpen, onClose, zIndex = "z-[100]" }) => {
    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className={`fixed inset-0 bg-black/50 backdrop-blur-sm ${zIndex}`}
        />
    );
};

export default Backdrop; 